import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import moment from "moment";

import Gauge from "../components/evap/Gauge";
import EvapTable from "../components/evap/evaptable";

function EvapPage(props) {
  const refreshRate = 1000 * 900;
  const facility = props.facility;
  const [lastUpdated, setlastUpdated] = useState(null);
  const [evapdata, setevapdata] = useState({
    "Evap1": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0,
    },
    "Evap2": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0,
    },
    "Evap3": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0,
    },
    "Evap4": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0,
    },
    "Evap5": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0,
    },
    "Evap6": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0
    },
    "Evap7": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0
    },
    "Evap8": {
      "DSPercent": 0,
      "SinceCIP": 0,
      "UntilCIP": 0,
      "DutyFlow": 0,
      "perfRating": 0
    },
    "dp1": 0,
    "dp2": 0
  });

  const LAST_UPDATED_FMT = "MM/DD/YYYY h:mm:ss A";


  //update effect
  useEffect(() => {
    getProduction();
    setlastUpdated(moment().format(LAST_UPDATED_FMT));
    const interval = setInterval(() => {
      getProduction();
      setlastUpdated(moment().format(LAST_UPDATED_FMT));
      console.log(lastUpdated);
    }, refreshRate);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProduction = async () => {
    try {
      let data = null;
      let cache = Math.floor(Math.random() * 100);
      if (facility === "north") {
        data = await fetch(`https://sightglass-func-01.azurewebsites.net/api/getevapdata?code=RrqRagyez6k8sRXMJL_sNJip-y_beOZeGTaS_CMvNKA3AzFuJ_mHuA==&cache=${cache}&facility=north`).then((response) =>
          response.json()
        );
      } else {
        data = await fetch(`https://sightglass-func-01.azurewebsites.net/api/getevapdata?code=RrqRagyez6k8sRXMJL_sNJip-y_beOZeGTaS_CMvNKA3AzFuJ_mHuA==&cache=${cache}&facility=south`).then((response) => response.json());
        console.log("got south facility");
      }

      //Update service status
      setevapdata(data);
      console.log(data);

      //Catch errors
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div>
      <div >
        <Container fluid>
          <Row>
            <Col><div className="dpgauge"><p className="dptxt">dp1: {evapdata.dp1}</p></div></Col>
            <Col><div className="dpgauge"><p className="dptxt">dp2: {evapdata.dp2}</p></div></Col>
            <Col><div className="dpgauge"><p className="dptxt">Facility: {facility}</p></div></Col>
          </Row>
          <Row>
            <Col><Gauge value={evapdata.Evap1.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}1`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap2.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}2`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap3.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}3`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap4.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}4`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap5.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}5`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap6.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}6`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap7.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}7`}></Gauge></Col>
            <Col><Gauge value={evapdata.Evap8.perfRating} size={120} mvalue={100} title={`EVAP ${facility === 'south' ? '2' : ''}8`}></Gauge></Col>
          </Row>
          <Row>
          <Col><EvapTable value={evapdata.Evap1.perfRating} mvalue={100} ds={evapdata.Evap1.DSPercent} rate={evapdata.Evap1.DutyFlow} since={evapdata.Evap1.SinceCIP} until={evapdata.Evap1.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap2.perfRating} mvalue={100} ds={evapdata.Evap2.DSPercent} rate={evapdata.Evap2.DutyFlow} since={evapdata.Evap2.SinceCIP} until={evapdata.Evap2.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap3.perfRating} mvalue={100} ds={evapdata.Evap3.DSPercent} rate={evapdata.Evap3.DutyFlow} since={evapdata.Evap3.SinceCIP} until={evapdata.Evap3.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap4.perfRating} mvalue={100} ds={evapdata.Evap4.DSPercent} rate={evapdata.Evap4.DutyFlow} since={evapdata.Evap4.SinceCIP} until={evapdata.Evap4.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap5.perfRating} mvalue={100} ds={evapdata.Evap5.DSPercent} rate={evapdata.Evap5.DutyFlow} since={evapdata.Evap5.SinceCIP} until={evapdata.Evap5.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap6.perfRating} mvalue={100} ds={evapdata.Evap6.DSPercent} rate={evapdata.Evap6.DutyFlow} since={evapdata.Evap6.SinceCIP} until={evapdata.Evap6.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap7.perfRating} mvalue={100} ds={evapdata.Evap7.DSPercent} rate={evapdata.Evap7.DutyFlow} since={evapdata.Evap7.SinceCIP} until={evapdata.Evap7.UntilCIP}></EvapTable></Col>
            <Col><EvapTable value={evapdata.Evap8.perfRating} mvalue={100} ds={evapdata.Evap8.DSPercent} rate={evapdata.Evap8.DutyFlow} since={evapdata.Evap8.SinceCIP} until={evapdata.Evap8.UntilCIP}></EvapTable></Col>
          </Row>
        </Container>



          

      </div>
          

    </div>
  );
}

export default EvapPage;
