import { useEffect, useState } from "react";
import moment from "moment";
import { take } from "lodash";

import CoreValue from "../components/news/corevalues.js";
import BoxTitle from "../components/news/boxtitle.js";
import ProdGallonsDisplay from "../components/news/ProdGallonsDisplay";

import sightglassLogo from "../assets/images/sightglass.png";
import EmployeeOfTheMonth from "../components/EmployeeOfTheMonth.jsx";
import HeadlineDisplay from "../components/news/HeadlineDisplay.jsx";
import WeatherDisplay from "../components/news/WeatherDisplay.jsx";
import WeatherRadarDisplay from "../components/news/WeatherRadarDisplay.jsx";
import SpotPrice from "../components/news/SpotPrice.jsx";

function News(props) {
  const refreshRate = 1000 * 300;
  const facility = props.facility;
  const [prodGalQ, setprodGalQ] = useState(0);
  const [prodGalM, setprodGalM] = useState(0);
  const [EOMdata, setEOMdata] = useState([]);
  //const [tweets, setTweets] = useState(null);
  const [isNational, setisNational] = useState(true);
  const [localNews, setLocalNews] = useState(null);
  const [nationalNews, setnationalNews] = useState(null);
  const [lastUpdated, setlastUpdated] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [weatherConditions, setWeatherConditions] = useState(null);
  const [weatherForecast, setWeatherForecast] = useState(null);
  const [radarFrame, setRadarFrame] = useState([""]);
  const [cframe, setcframe] = useState(0);
  const [cornOilSpot, setcornOilSpot] = useState(null);
  const [cornSpot, setcornSpot] = useState(null);
  const [ddgsSpot, setddgsSpot] = useState(null);
  const [ethanolSpot, setethanolSpot] = useState(null);

  const CLOCK_FORMAT_A = "MMMM Do, YYYY - h:mm A";
  const CLOCK_FORMAT_B = "MMMM Do, YYYY - h mm A";
  const LAST_UPDATED_FMT = "MM/DD/YYYY h:mm:ss A";

  const getEOMs = async () => {
    try {
      //Fetch data from azure functions SQL https://service-status-functions.azurewebsites.net/api/EOMread?code=3KGYM2lV6Rpjo1vFCFv_2_tz2Ru_7TLx2GzH4MKDAb3EAzFu_L4wEw==
      
      let cache = Math.floor(Math.random() * 100);
              let data = await fetch('https://sightglass-func-01.azurewebsites.net/api/eomread?' + new URLSearchParams( 
              {
                code: 'F3zsfkuGeWlyLJkCUtTnzCNYQGkvdD0tZpJJDrjc_u7OAzFucm1cNw==',
                cache: cache
              })).then((response) => response.json());


      //Update service status
      var result = [];
      var keys = Object.keys(data);
      keys.forEach(function (key) {
        result.push(data[key]);
      });
      result.sort((a, b) => ((a.FirstName + " " + a.LastName).toString() > (b.FirstName + " " + b.LastName).toString() ? 1 : -1));
      setEOMdata(result);
      //Catch errors
    } catch (error) {
      console.log(error);
    }
  };

  const getProdStatus = async () => {
    let cache = Math.floor(Math.random() * 100);
    try {
      let lastQuarter = await fetch(`https://api-01.marquisenergy.com/productiongallons/lastquarter?facility=${facility}&cache=${cache}`).then((response) => response.json());
      let lastMonth = await fetch(`https://api-01.marquisenergy.com/productiongallons/lastmonth?facility=${facility}&cache=${cache}`).then((response) => response.json());

      //Update service status
      setprodGalM(lastMonth);
      setprodGalQ(lastQuarter);
      //Catch errors
    } catch (error) {
      console.log(error);
    }
  };

  /*const getTweets = async () => {
    try {
      let cache = Math.floor(Math.random() * 100);
      let tweetsJson = await fetch(`https://sightglass-func-01.azurewebsites.net/api/tweets?code=Uu6JmGytXnr7O6rRcuCWYBgMt1FbOm0DAdqFMDf41VNGAzFunSX9Vw==&cache=${cache}`).then((response) => response.json());

      //Update service status
      setTweets(tweetsJson);
      //Catch errors
    } catch (error) {
      console.log("An error occurred while loading the Twitter feed.");
      console.log(error);
    }
  };*/

  const getNews = async () => {
    try {
      //Fetch data from azure functions SQL
      /*
              let response = await fetch('https://marquis-status-backend.azurewebsites.net/api/StatusFunction?' + new URLSearchParams( 
              {
                code: '0N4RW6JT860duLeT6449KD6tZKRv7DgiR-OF8RPw3blJAzFuI9Rukg=='
              }));
              */

      let requestUrl = "";
      let cache = Math.floor(Math.random() * 100);
      if (facility.toLowerCase() === "illinois") {
        requestUrl = `https://sightglass-func-01.azurewebsites.net/api/illinois/news?code=7jPXYNo32BPyuARnn2IxyodPTpJXyM4Uoj71Wb5P0peJAzFuzcJiDQ==&cache=${cache}`;
      } else {
        requestUrl = `https://sightglass-func-01.azurewebsites.net/api/wisconsin/news?code=d6tai1TaO8DsOdegCkzgRaZsuVKHBQ-tebDL7sD3tueSAzFu-K7-PA==&cache=${cache}`;
      }
      let localNews = await fetch(requestUrl).then((response) => response.json());
      //Update service status
      setLocalNews(take(localNews, 4));
      //Catch errors
    } catch (error) {
      console.warn("An error occurred while loading the local news.");
      console.log(error);
    }

    try {
      let cache = Math.floor(Math.random() * 100);
      let nationalNews = await fetch(`https://sightglass-func-01.azurewebsites.net/api/news?code=S88Kr0sVbovLYy2fZjxpmrKn2FIRjE79nvRiCa6POgGgAzFujeLekA==&cache=${cache}`).then((response) => response.json());
      setnationalNews(take(nationalNews, 4));
    } catch (error) {
      console.warn("An error occurred while loading the local news.");
      console.log(error);
    }
  };

  const getWeather = async () => {
    try {
      let requestUrl = "";
      let cache = Math.floor(Math.random() * 100);
      if (facility.toString().toLowerCase() === "illinois") {
        requestUrl = `https://sightglass-func-01.azurewebsites.net/api/illinois/weather/conditions?code=w7oTXA2hX5hODTSG0ZldwQhdzSJXYon4vk0ZyaH-AOgJAzFuocVFug==&cache=${cache}`;
      } else {
        requestUrl = `https://sightglass-func-01.azurewebsites.net/api/wisconsin/weather/conditions?code=uWVBbVUfIYjAq_aup4gO5Y1aeXuhiXqZ6KdIJKRWAqkZAzFuoUkd-w==&cache=${cache}`;
      }
      let conditions = await fetch(requestUrl).then((response) => response.json());
      //Update service status
      setWeatherConditions(conditions, 0);
      //Catch errors
    } catch (error) {
      console.warn("An error occurred while loading the local news.");
      console.log(error);
    }

    try {
      let requestUrl = "";
      let cache = Math.floor(Math.random() * 100);
      if (facility.toString().toLowerCase() === "illinois") {
        requestUrl = `https://sightglass-func-01.azurewebsites.net/api/illinois/weather/forecast?code=Tpkutt8cKp-T93_YCM3eFsdPZgbm63N-HirR1IJk8bKYAzFu8FUQqg==&cache=${cache}`;
      } else {
        requestUrl = `https://sightglass-func-01.azurewebsites.net/api/wisconsin/weather/forecast?code=-lfzxRGq1TPk0RqAouUiUe0VnkP2T_ehzMGlWqnxx0kgAzFuKrHEaw==&cache=${cache}`;
      }
      let forecast = await fetch(requestUrl).then((response) => response.json());
      setWeatherForecast(forecast, 0);
    } catch (error) {
      console.warn("An error occurred while loading the local news.");
      console.log(error);
    }
  };

  const getRadarFrames = async () => {
    try {
      let cache = Math.floor(Math.random() * 100);
      let prefix = `https://api-01.marquisenergy.com/weather/radar?facility=${facility}&cache=${cache}`;
      const radarFrames = [];
      for (let i = 0; i < 12; i++) {
        let frame = { dttm: null, url: "" };
        let frameRequest = await fetch(`${prefix}&offset=${i}`).then((response) => response.json());

        frame.dttm = moment(frameRequest.retrieveDttm);
        frame.url = frameRequest.base64;

        radarFrames.unshift(frame);
      }
      setRadarFrame(radarFrames);

      //Catch errors
    } catch (error) {
      console.log(error);
    }
  };

  const getSpotPrices = async () => {
    try {
      let cache = Math.floor(Math.random() * 100);
      let cornSpot = await fetch(`https://api-01.marquisenergy.com/SpotPrice/corn?cache=${cache}`).then((response) => response.json());
      setcornSpot(cornSpot);
    } catch (reason) {
      console.log("An error occurred while loading the corn spot price.");
      console.log(reason);
    }

    try {
      let cache = Math.floor(Math.random() * 100);
      let cornOilSpot = await fetch(`https://api-01.marquisenergy.com/SpotPrice/cornoil?cache=${cache}`).then((response) => response.json());
      setcornOilSpot(cornOilSpot / 100);
    } catch (reason) {
      console.log("An error occurred while loading the corn oil spot price.");
      console.log(reason);
    }

    try {
      let cache = Math.floor(Math.random() * 100);
      let ddgsSpot = await fetch(`https://api-01.marquisenergy.com/SpotPrice/ddgs?cache=${cache}`).then((response) => response.json());
      setddgsSpot(ddgsSpot);
    } catch (reason) {
      console.log("An error occurred while loading the DDGS spot price.");
      console.log(reason);
    }

    try {
      let cache = Math.floor(Math.random() * 100);
      let ethanolSpot = await fetch(`https://api-01.marquisenergy.com/SpotPrice/ethanol?cache=${cache}`).then((response) => response.json());
      setethanolSpot(ethanolSpot);
    } catch (reason) {
      console.log("An error occurred while loading the ethanol spot price.");
      console.log(reason);
    }
  };

  //update effect
  
  useEffect(() => {
    getProdStatus();
    getEOMs();
    //getTweets();
    getNews();
    getWeather();
    getRadarFrames();
    getSpotPrices();

    setlastUpdated(moment().format(LAST_UPDATED_FMT));

    const interval = setInterval(() => {
      getProdStatus();
      getEOMs();
      //getTweets();
      getNews();
      getWeather();
      getRadarFrames();
      getSpotPrices();
      if (radarFrame) {
      }
      setlastUpdated(moment().format(LAST_UPDATED_FMT));
    }, refreshRate);
    return () => clearInterval(interval);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated]);

  //News story switcher -> 15s
  useEffect(() => {
    const interval = setInterval(() => {
      setisNational((prevCounter) => !prevCounter);
      console.log("Last Updated: " + lastUpdated);
    }, 22 * 1000);
    return () => clearInterval(interval);
  }, [lastUpdated]);

  //time label and radar -> 1s
  useEffect(() => {
    let format = CLOCK_FORMAT_A;
    const interval = setInterval(() => {
      format.toString() === CLOCK_FORMAT_A.toString() ? (format = CLOCK_FORMAT_B) : (format = CLOCK_FORMAT_A);
      setCurrentTime(moment().format(format), 0);
      
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //radar -> 3s
  useEffect(() => {
    const interval = setInterval(() => {
      //radar
      let nframe = cframe + 1;
      if (radarFrame != null && nframe > radarFrame.length - 1) {
        setcframe(0, 0);
      } else {
        setcframe(nframe, 0);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [radarFrame, cframe]);

  return (
    <div>
      <div className='colLeft'>
        <div className='logoWrapper'>
          <img alt="logo" src={sightglassLogo}></img>
        </div>

        <div className='prodWrapper'>
          <BoxTitle title='Production Gallons' color='#73bf45'></BoxTitle>
          <ProdGallonsDisplay prodGalQ={prodGalQ} prodGalM={prodGalM}></ProdGallonsDisplay>
        </div>

        <div className='valuesWrapper'>
          <BoxTitle title='Core Values' color='rgb(54,148,192)'></BoxTitle>
          <CoreValue title='Safety'>Our first commitment to each other.</CoreValue>
          <CoreValue title='Honesty'>A foundation for meaningful relationships.</CoreValue>
          <CoreValue title='Excellence'>Cultivating success through collaboration and hard work.</CoreValue>
        </div>
      </div>

      <div className='colMiddle'>
        <div className='EOMWrapper'>
          <BoxTitle title='Employees of the Month' color='#3e94c0'></BoxTitle>
          <div>
            {
              //Map first 3 results to eom
              EOMdata.slice(0, 3).map((eom) => {
                return (
                  <EmployeeOfTheMonth
                    key={eom.Id}
                    date={eom.DateAwarded}
                    FirstName={eom.FirstName}
                    LastName={eom.LastName}
                    Branch={eom.Branch}
                    PhotoURL={eom.PhotoUrl}
                    Title={eom.Title}></EmployeeOfTheMonth>
                );
              })
            }
          </div>
        </div>

        <div className='NewsWrapper'>
          {isNational ? <BoxTitle title='National News' color='#73bf45'></BoxTitle> : <BoxTitle title='Local News' color='#73bf45'></BoxTitle>}
          {
            // is national -> cnn else illinois else wisconsin
            isNational ? (
              <div>
                <HeadlineDisplay stories={nationalNews}></HeadlineDisplay>
                <div className='source'>Source: cnn.com</div>
              </div>
            ) : facility === "illinois" ? (
              <div>
                <HeadlineDisplay stories={localNews}></HeadlineDisplay>
                <div className='source'>Source: shawlocal.com/bureau-county-republican</div>
              </div>
            ) : (
              facility === "wisconsin" && (
                <div>
                  <HeadlineDisplay stories={localNews}></HeadlineDisplay>
                  <div className='source'>Source: wrjc.com</div>
                </div>
              )
            )
          }
        </div>
      </div>

      <div className='colRight'>
        <div className='WeatherWrapper'>
          <BoxTitle title={currentTime} color='#959595'></BoxTitle>
          <WeatherDisplay conditions={weatherConditions} forecast={weatherForecast} />
        </div>

        <div className='RadarWrapper'>
          <WeatherRadarDisplay facility={facility} src={radarFrame} frame={cframe} />
        </div>

        <div className='SpotWrapper'>
          <div class='Spotbox'>
            <SpotPrice price={ethanolSpot} units='gal' color='#42a0cc' title='Spot Ethanol' />
          </div>
          <div class='Spotbox'>
            <SpotPrice price={ddgsSpot} units='ton' color='#69a644' title='Spot DDGS' />
          </div>
          <div class='Spotbox'>
            <SpotPrice price={cornOilSpot} units='lb' color='#a7aa45' title='Spot Corn Oil' />
          </div>
          <div class='Spotbox2'>
            <SpotPrice price={cornSpot} units='bu' color='#434343' title='Spot Corn' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
